/* You can add global styles to this file, and also import other style files */
/* You can add global styles to this file, and also import other style files */
@import"../node_modules/@angular/material/prebuilt-themes/indigo-pink.css";
@import "~ag-grid-community/styles/ag-grid.css";
@import "~ag-grid-community/styles/ag-theme-balham.css";
@import 'quill/dist/quill.core.css';
@import 'quill/dist/quill.snow.css';

/* added common css for ag-grid tables */

.ag-column-drop-wrapper {
    .ag-column-drop {
        margin-bottom: 15px;
        height: 50px;
        background: #F2F2F2D8 0% 0% no-repeat padding-box;
        border: 1px dashed #999B9D;
        border-radius: 11px;
        opacity: 1;
    }
}

.ag-column-drop-empty-message.ag-column-drop-horizontal-empty-message {
    text-align: left;
    letter-spacing: 0px;
    color: #161718;
    opacity: 0.43;
}

.ag-theme-balham .ag-header {
    background-color: #F4F0DF;
}

.ag-aria-description-container {
    display: none;
}

.ag-header-cell-label {
    display: flex;
    justify-content: space-between;
    white-space: normal;
}

.ag-header-cell-text {
    font-family: normal normal medium Roboto, sans-serif !important;
    font-weight: 600;
    color: #161718;
}

.ag-header-group-cell.ag-header-group-cell-with-group.ag-focus-managed {
    justify-content: center;
    font: normal normal normal 12px/14px 'Roboto', sans-serif;
    font-weight: 600;
    color: #161718;
}

.ag-cell-value, .ag-group-value, .ag-group-child-count {
    color: #686A6E;
    font-family: 'Roboto', sans-serif;
}

.ag-row-odd {
    background-color: #F7F7F7;
}

.ag-header-cell.ag-focus-managed {
    border: 1px solid lightgrey;
}

.ag-floating-filter.ag-focus-managed {
    border: 0;
}

.ag-ltr .ag-header-cell::before {
    visibility: hidden;
}

/*AG-Grid pagination panel issue fix*/
.ag-paging-panel > * {
    margin: 2px;
  }
















/** Above are AG-GRID Table common css */

/** ABOVE are Modal common css */

.appFormLayout {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: inset 0px 2px 0px #BCD1E0, 0px 3px 6px #00000029;
    opacity: 1;
}

.text-styling {
    text-align: left;
    font: normal normal normal 14px/16px 'Roboto', sans-serif;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
}

.label-styling {
    text-align: left;
    font: normal normal normal 13px/19px 'Roboto', sans-serif;
    letter-spacing: 0px;
    text-transform: capitalize;
    opacity: 1;
}

input {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: inset 0px 1px 6px #00000029;
    font: normal normal normal 13px/15px 'Roboto', sans-serif;
    border: 1px solid #D7DCE1;
    border-radius: 2px;
    opacity: 1;
}

.form-control {
    width: 20rem;
    height: 34px;
    font: normal normal normal 13px/15px 'Roboto', sans-serif;
}

.form-select {
    width: 186px;
    height: 34px;
    font: normal normal normal 13px/15px 'Roboto', sans-serif;
}

.icon-style {
    top: 273px;
    left: 1200px;
    width: 26px;
    height: 26px;
    background: transparent linear-gradient(180deg, #FFFFFF 0%, #E4ECF0 100%) 0% 0% no-repeat padding-box;
    border: 1px solid #D1D1D1;
    opacity: 1;
    margin-left: 98%;
}

.required::after {
    content: '*';
    text-align: left;
    font: normal normal normal 13px/19px 'Roboto', sans-serif;
    letter-spacing: 0px;
    color: #D20000;
    text-transform: capitalize;
}

.enablebutton {
    width: 88px;
    height: 37px;
    font: normal normal normal 14px/16px 'Roboto', sans-serif;
    background: black;
    opacity: 1;
    color: white;
    cursor: pointer;
}

.disablebutton {
    width: 88px;
    height: 37px;
    font: normal normal normal 14px/16px 'Roboto', sans-serif;
    background: black;
    opacity: 0.5;
    color: white;
    cursor: not-allowed;
}

.reset-link {
    cursor: pointer;
    font: normal normal normal 14px/16px 'Roboto', sans-serif;
    letter-spacing: 0px;
    text-transform: capitalize;
    opacity: 1;
}

.resetIcon {
    cursor: pointer;
    position: relative;
    top: 8px;
    width: 24px;
    height: 24px;
    background: transparent url('./assets/images/refresh.svg') 0% 0% no-repeat padding-box;
    opacity: 1;
}

.refreshIcon {
    cursor: pointer;
    position: relative;
    top: 13px;
    width: 24px;
    height: 24px;
    background: transparent url('./assets/images/refresh.svg') 0% 0% no-repeat padding-box;
    opacity: 1;
}

.cancel {
    text-align: left;
    font: normal normal medium 13px/16px 'Roboto', sans-serif;
    letter-spacing: 0px;
    color: #88C4FF;
    opacity: 1;
    padding-left: 15px;
    text-decoration: none;
}

.infoIcon {
    left: 1216px;
    top: 221px;
    width: 20px;
    height: 20px;
    cursor: pointer;
    background: transparent url('./assets/images/info-icon.svg') 0% 0% no-repeat padding-box;
    opacity: 1;
    transform: translateY(25%);
}

.displayButton {
    text-align: right;
}

.deleteIcon {
    top: 355px;
    left: 273px;
    width: 26px;
    height: 26px;
    background: transparent url('./assets/images/delete.svg') 0% 0% no-repeat padding-box;
    opacity: 1;
    cursor: pointer;
}

.heading {
    font-size: small;
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    color: #000000;
    text-transform: uppercase;
}




.addButton1 {
    float: right;
    height: 38px;
    text-align: center;
    width: 90px;
    text-transform: uppercase;
    color: white;
    opacity: 0.8;
    border-radius: 0;
    font-family: 'Roboto', sans-serif;
    font-size: 13px;
    line-height: 13px;
    background: transparent linear-gradient(180deg, #000000 0%, #000000 100%) 0% 0% no-repeat padding-box;
    margin: 1px;
    font-weight: 500;
    cursor: pointer;
}

.copywriteText {
    text-align: left;
    font: small;
    font-size: small;
    letter-spacing: 0px;
    color: #676A6E;
}

.closeButton {
    width: 100px;
    height: 38px;
    display: inline;
    background: transparent linear-gradient(180deg, #FFFFFF 0%, #E9F0F3 100%) 0% 0% no-repeat padding-box;
    border: 1.4px solid lightgray;
    opacity: 1;
    color: #676A6E;
    border-radius: 0px;
    text-align: center;
    letter-spacing: 0px;
    text-transform: uppercase;
    font-family: 'Roboto', sans-serif;
    font-size: 13px;
    line-height: 13px;
    float: right;
    align-content: center;
    margin: 1px;
    font-weight: 600;
    cursor: pointer;
}

.addButton {
    float: right;
    height: 38px;
    text-align: center;
    width: 90px;
    text-transform: uppercase;
    color: white;
    opacity: 0.8;
    border-radius: 0;
    font-family: 'Roboto', sans-serif;
    font-size: 13px;
    line-height: 13px;
    background: transparent linear-gradient(180deg, #000000 0%, #000000 100%) 0% 0% no-repeat padding-box;
    margin: 1px;
    cursor: pointer;
    font-weight: 600;
    border: none;
}

.addButton:active, .addButton1:active, .closeButton:active,
 .addButton:hover, .addButton1:hover, .closeButton:hover,
  .addButton:focus, .addButton1:focus, .closeButton:focus {
    border-color: #86b7fe;
    box-shadow: 0 0 0 2px rgba(13, 110, 253, 0.25);
}

.footer-tab {
    height: 90px;
    background: #E4ECF0 0% 0% no-repeat padding-box;
    padding-bottom: 20px;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #BCD1E0;
    opacity: 1;
    z-index: 29 !important;

}

.search-tmna {
    font-size: small !important;
    font-weight: 500;
    width: 80%;
    background-position: calc(100% - 0.2rem) center !important;
    -moz-appearance: none !important;
    -webkit-appearance: none !important;
    appearance: none !important;
    height: calc(2.05rem + 0px) !important;
}

.addsearch {

    background-color: white;
    display: inline-block;
    cursor: pointer;
}

.clpseBtn.active {
    content: url('./assets/images/Collapse.png');
}

.clpseBtn {
    float: right;
    position: relative;
    top: -3px;
    content: url('./assets/images/Expand.svg');
}

.card {
    background: #E4ECF0 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #BCD1E0;
    opacity: 1;
    border-radius: 0;
}

.accordion-wrapper {
    width: 100%;
    max-width: 10000px;
}

.cardBody {
    background: white;

    box-shadow: 3px 3px 6px 3px #00000029;
    border-top: 4px solid #BCD1E0;
    opacity: 1;

}


.select-tmna {
    color: grey !important;
    font-size: 12px !important;
    font-weight: 200;
    background: url(/assets/images/Dropdown.svg) no-repeat !important;
    background-position: calc(100% - 0.75rem) center !important;
    -moz-appearance: none !important;
    -webkit-appearance: none !important;
    appearance: none !important;
    padding-right: 2rem !important;
    height: calc(2.05rem + 0px) !important;
}

/* common Css for dropdowns and the input fieldset */
.subHeading {

    color: #676A6E;
    font-size: small;
    font-weight: 200;

}

.label-tmna {
    color: #686A6E;
    font-size: small;
    font-weight: 200;
}
.mat-calendar-header{
    background-color:#BCD1E0 ;
}

.mat-calendar-controls{
    margin:0% calc(4.7142857143% - 16px);
}

.dropdown-tmna {
    width: 198px;
    height: 35px;
    font-size: 12px;
    color: #686A6E;
    cursor: pointer;
    padding: 4px 8px 4px 8px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #D7DCE1;
    border-radius: 2px;
    box-shadow: inset 0px 1px 6px #00000029;
    opacity: 1;
}

.dropdown-tmna:disabled {
    color: #C0C1C2;
    background: #FBFBFC 0% 0% no-repeat padding-box;
    pointer-events: none;
}

.input-tmna {
    width: 160px;
    height: 35px;
    font-size: 12px;
    color: #686A6E;
    padding: 4px 8px 4px 8px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #D7DCE1;
    border-radius: 2px;
    box-shadow: inset 0px 1px 6px #00000029;
    opacity: 1;
}

.input-tmna:focus {
    border-color: grey !important;
    outline: none;
}

.input-tmna:disabled{
    letter-spacing: 0px;
    color: #C0C1C2;
    opacity: 1;
    background: #FBFBFC 0% 0% no-repeat padding-box;
    box-shadow: inset 0px 1px 6px #00000029;
    border: 1px solid #D7DCE1;
    border-radius: 2px;
}

.label-tmna-big{
    width: 85%;
    font-size: 12px;
    color: #686A6E;
    padding: 4px 8px 4px 8px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #D7DCE1;
    border-radius: 2px;
    box-shadow: inset 0px 0px 3px #00000029;
}
.commonFont{
    font-size: small;
    font-weight: 600;
    padding-top: 5px;
    font-family: 'Roboto', sans-serif;
}
.displayTextcss{
    font-size: small;
    color: black;
    padding: 4px;
    cursor: pointer;
}
.displayTextcss:hover{
    font-weight: 700;
    color: grey;
}
.cursorptr{
    cursor: pointer;
}

/* Mat table style */
.mat-mdc-tab-list {
    border-bottom: 1px solid #676A6E;
}

.mat-mdc-tab-group-stretch-tabs {
    width: 100%;
}

.mat-mdc-tab-body-content {
    overflow-x: hidden !important;
}

.mdc-tab__text-label {
    font-family: 'Roboto', sans-serif !important;
    letter-spacing: normal;
    font-size: small;   
}

.mat-mdc-tab .mdc-tab__text-label {
    color: grey;
}

.mat-mdc-tab.mdc-tab--active .mdc-tab__text-label {
    color: black !important;
    font-weight: 500;
}

.mat-mdc-tab .mdc-tab-indicator__content--underline {
    border-top-width: 0px !important;
}

.mdc-tab {
    padding-right: 18px !important;
    padding-left: 18px !important;
}

.mdc-tab--active {
    border-top: 4px solid #d20000 !important;
    border-right: 2px solid lightgrey !important;
    border-left: 2px solid lightgrey !important;
    margin-bottom: 5px !important;
    box-shadow: 1px 5px 3px #00000029;
}

.mat-mdc-tab-labels {
    width: 30%;
}

.card-header {
    padding-right: 0;
}

.alert-success {
    background: #25AB0133 0% 0% no-repeat padding-box;
    border: 1px solid #25AB0199;
    color: #25AB01;
    opacity: 1;
    height: 40px;
    text-align: left;
    letter-spacing: 0px;
    position: relative;
    padding: 9px 20px;
    font-size: 13px;
    font-family: 'Roboto',sans-serif;
}

.alert-close {
    margin-left: 1vw;
    color: darkgreen;
    font-weight: bold;
    float: right;
    font-size: 22px;
    line-height: 20px;
    cursor: pointer;
    transition: 0.3s;
}
.alert-failure {
    color: #721c24;
    background-color: #f8d7da;
    position: relative;
    padding: 9px 20px;
    border: 1px solid black;
    border-color: #f5c6cb;
    font-size: 13px;
    font-family: 'Roboto',sans-serif;
    opacity: 1;
    height: 40px;
    text-align: left;
    letter-spacing: 0px;
}