.btn-group-vertical > button{
    margin-bottom:10px;
    border-radius:10px !important;
}

.dark-modal .modal-content {
    background-color: #292b2c;
    color: white;
}

.dark-modal .close {
    color: white;
}

.light-blue-backdrop {
    background-color: #5cb3fd;
}

.app-name {
    font-size: medium;
    letter-spacing: 0;
    color: #D20000;
    opacity: 1;
    font-weight: 700;
    border: none;
    background-repeat: no-repeat;
    overflow: hidden;
    outline: none;
    text-transform: uppercase;
}

.app-name:hover {
    color: #D20000;
}

.app-title{
    font-size: 13px;
    letter-spacing: 0;
    color:black;
    opacity: 1;
    font-weight: 500;
    margin: auto;
    text-transform: uppercase;
}

.nav-custom {
    border-bottom: 1px solid #b0b0b0;
    padding-bottom: .1px;
    background: transparent linear-gradient(180deg, #fff, #f4f4f4) 0 0 no-repeat padding-box;
}
.navbar-nav .nav-item1{
    width: 120px;
    text-align: center;
}

.nav-custom-items { 
    font-size: smaller;
    width: 170px;
    padding: 0px;
    font-weight: 500;
}

.nav-custom-items > a {
    color: black;
    text-decoration: none;
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    height: 55px;
}


.loginname{
    font-size: 13px;
    text-transform: uppercase;
}
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
